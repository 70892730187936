import '../scss/qdistributors_front.scss';
"use-strict"
let jQuery = require('jquery');

window.J    = jQuery;
window.Jd   = J(document);

Jd.ready(function(){

    if (J('#qdistributors_selector_container').length && J('#qdistributors_selector').length) {

        Jd.off('change', '#qdistributors_selector').on('change', '#qdistributors_selector', function(){

            J('#qdistributors_loader').show();

            var value = J(this).val();
            if (value != '-') {
                window.location.href = window.location.pathname+'?country='+value;
            } else {

                J('#qdistributors_loader').hide();
            }
        });
    }
});


